export const isLoginPopupAction = (data) =>{
    if(data.isShow){
        return {
            type: "LOGIN_POPUP_OPEN",
            payload: data
        }
    }else{
        return {
            type: "LOGIN_POPUP_CLOSE",
            payload: data
        }
    }
    
}

export const loginScreenAction = (data) =>{
    return {
        type: "LOGIN_SCREEN_ACTION",
        payload: data
    }
}
export const singleLineHeader = (data) => {
	return {
		type: "SHOW_SINGLE_LINE_HEADER",
		payload: data
	}
}
export const singleLineHeaderLinkTo = (data) => {
	return {
		type: "SINGLE_LINE_HEADER_LINK_TO",
		payload: data
	}
}
export const headerTextAction = (val) => {
	return {
		type: "HEADER_TEXT_ACTION",
		payload: val
	}
}
export const showHeaderIconsAction = (val) => {
	return {
		type: "SHOW_HEADER_ICONS_ACTION",
		payload: val
	}
}
export const cartOrderSummaryAction = (data) =>{
    return {
        type:"CART_ORDER_SUMMARY",
        payload:data
    }
}
export const cartVariantAction = (data) =>{
    return {
        type:"CART_VARIANT",
        payload:data
    }
}
export const cartPackAction = (data) =>{
    return {
        type:"CART_PACK",
        payload:data
    }
}
export const cartOfferAction = (data) =>{
    return {
        type:"CART_OFFER",
        payload:data
    }
}
export const paymentOfferAction = (data) =>{
    return {
        type:"PAYMENT_OFFER",
        payload:data
    }
}
export const cartAppliedOfferAction = (data) =>{
    return {
        type:"CART_APPLIED_OFFER",
        payload:data
    }
}
export const cartHeaderSummaryAction = (data) =>{
    return {
        type:"CART_HEADER_SUMMARY",
        payload:data
    }
}
export const cartAddressAction = (data) =>{
    return {
        type:"CART_ADDRESS",
        payload:data
    }
}

export const appliedMBCashChangeAction = (data) =>{
    return {
        type:"MB_CASH_TOGGLE",
        payload:data
    }
}
export const orderSummaryPopUpAction = (data) =>{
    return {
        type:"ORDER_SUMMARY_POPUP",
        payload:data
    }
}

// export const isLoggedinAction = (loggedIn) => async (dispatch) => {
//     dispatch({ type: "LOGGEDIN", loggedIn });
// }

export const isLoggedinAction = (val) =>{
    return{
        type:"LOGGEDIN_ACTION",
        loggedIn:val
    }
};

export const requestQueryAction = (val) =>{
    return{
        type:"REQUEST_QUERY_ACTION",
        requestQuery:val
    }
};
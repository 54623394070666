export const CARTAction = (cart) => {
	return {
		type: "CART_CHANGE_ACTION",
		payload: cart
	}
}
export const mobMenuAction = (menuData) => {
	return {
		type: "MOB_MENU_ACTION",
		payload: menuData
	}
}
export const mobSearchOpenAction = (data) => {
	return {
		type: "MOB_SEARCH_OPEN",
		payload: data
	}
}
export const mobMenuOpenAction = (data) => {
	return {
		type: "MOB_MENU_OPEN",
		payload: data
	}
}
export const searchStateAction = (data) => {
	return {
		type: "SEARCH_STATE",
		payload: data
	}
}
export const headerTransparentAction = (data) => {
	return {
		type: "HEADER_TRANSPARENT",
		payload: data
	}
}
export const videoShowAction = (data) => {
	return {
		type: "VIDEO_SHOW",
		payload: data
	}
}
export const headerMenuAction = (data) => {
	return {
		type: "MENU_SWITCH",
		payload: data
	}
}
export const MenuNodeAction = (data) => {
	return {
		type: "MENU_NODES",
		payload: data
	}
}
export const MBCashAvailableAction = (data) => {
	return {
		type: "IS_MBCASH",
		payload: data
	}
}

export const userRoleAction = (cart) => {
	return {
		type: "USER_ROLE_ACTION",
		payload: cart
	}
}

export const singleLineFooter = (data) => {
	return {
		type: "SHOW_SINGLE_LINE_FOOTER",
		payload: data
	}
}
export const footerAction = (data) => {
	return {
		type: "SHOW_FOOTER_ACTION",
		payload: data
	}
}